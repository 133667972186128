<template>
  <div class="mt-2">
    <slot name="activator" v-bind:onShowModal="onShowModal">
      <div class="mb-2">
        <b-button @click="onShowModal" variant="success" size="sm">Seleccionar</b-button>
        <span class="ml-2" v-if="selected.length > 0">{{ selected.length }} seleccionados</span>
        <span class="ml-2" v-else>No hay seleccionados</span>
      </div>
      <div v-if="selected.length > 0">
        <b-table
          id="seleccionadosTable"
          :fields="[{ key: 'identificacion', label: 'Seleccionados' }]"
          :items="selected"
          sticky-header
          striped
          bordered
          responsive
          hover
          show-empty
          small
          :filter="filter"
        >
          <template #cell(identificacion)="row">
            <span class="ml-1 mb-1 my-cursor text-black small" @click="selectRow(row.item)">{{ `${row.item.identificacion} (${row.item.email})` }}</span>
          </template>
        </b-table>
      </div>
    </slot>

    <b-modal
      v-model="modal"
      centered
      size="lg"
      scrollable
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      dialog-class="x-modal-md"
    >
      <template #modal-title>
        <span>Seleccionar destinatarios...</span>
      </template>

      <b-card no-body>
        <b-card-header>
          <span class="mb-2">Listado de inversionistas</span>
          <div class="ml-auto d-flex justify-content-end align-items-center">
            <b-form-select v-model="empresa_id" :options="options" size="sm" class="w-100 mx-2" style="max-width: 400px"
                           :disabled="loading_options" @change="onChangeOption"
            ></b-form-select>
            <search-bar
              placeholder="Buscar por rfc, correo o razón social"
              @filter="(filter) => this.filter = filter"></search-bar>
          </div>
          <b-form-checkbox v-model="selectAll" switch inline class="user-select-none">
            Seleccionar todos...
          </b-form-checkbox>
        </b-card-header>
        <b-card-body>
          <b-overlay :show="loading">
            <template #overlay>
              <div class="text-center">
                <loading message="Obteniendo información, por favor espere..." />
              </div>
            </template>
            <div>
              <b-table
                id="mensajesTable"
                :fields="fields"
                :items="inversionistas"
                sticky-header
                striped
                bordered
                responsive
                hover
                show-empty
                small
                :filter="filter"
                empty-text="No se encontraron inversionistas con departamentos asignados a la empresa o desarrollo seleccionados"
                empty-filtered-text="No se encontraron inversionistas con los parámetros de búsqueda escritos"
              >
                <template #cell(rfc)="row">
                  <filtered-row :value="row.value" :filter="filter"/>
                </template>
                <template #cell(email)="row">
                  <filtered-row :value="row.value" :filter="filter"/>
                </template>
                <template #cell(identificacion)="row">
                  <filtered-row :value="row.value" :filter="filter"/>
                </template>
                <template #cell(selected)="row">
                  <b-form-checkbox v-model="row.value" @change="selectRow(row.item)" switch inline class="user-select-none">
                  </b-form-checkbox>
                </template>
              </b-table>
            </div>
          </b-overlay>
        </b-card-body>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import SearchBar from '../../Common/Views/SearchBar.vue'
import FilteredRow from '../../Common/Table/FilteredRow.vue'
export default {
  name: 'MensajesDestinatariosModal',

  props: {
    value: {
      default: []
    }
  },

  components: {
    SearchBar,
    FilteredRow
  },

  mounted () {
    this.init()
  },

  data () {
    return {
      modal: false,

      loading: false,

      fields: [
        { key: 'rfc', label: 'RFC' },
        { key: 'email', label: 'Correo' },
        { key: 'identificacion', label: 'Razón social' },
        { key: 'selected', label: 'Seleccionado' }
      ],

      inversionistas: [],

      filter: '',

      selected: [],

      selectAll: false,

      empresa_id: null,
      options: [],
      loading_options: true
    }
  },

  watch: {
    value: {
      deep: true,
      immediate: true,
      handler (value) {
        if (value === '') {
          this.selected = []
        }
      }
    },

    selected: {
      deep: true,
      immediate: true,
      handler (value) {
        this.emit(value)
      }
    },

    selectAll: {
      immediate: true,
      handler (value) {
        for (let i = 0; i < this.inversionistas.length; i++) {
          if (value) {
            if (!this.isDuplicate(this.inversionistas[i])) {
              this.selected.push(this.inversionistas[i])
              this.inversionistas.find(row => row.id === this.inversionistas[i].id).selected = true
            }
          } else {
            if (this.isDuplicate(this.inversionistas[i])) {
              this.removeItem(this.inversionistas[i])
              this.inversionistas.find(row => row.id === this.inversionistas[i].id).selected = false
            }
          }
        }
      }
    }
  },

  methods: {
    /**
     * Realiza la carga inicial del listado de inversionistas
     */
    async init () {
      this.loading = true
      const response = await this.$store.dispatch('inversionistaModule/getSelectListAll')
      // Inicialmente no filtramos la información
      this.inversionistas = response.data.map((item) => {
        return {
          id: item.id,
          rfc: item.rfc,
          email: item.email,
          identificacion: item.identificacion,
          selected: false
        }
      })
      this.loading = false

      this.loading_options = true
      this.load_options()
      this.loading_options = false
    },

    async load_options () {
      // Load options empresa
      const { data } = await this.$store.dispatch('empresaModule/getSelectTree')
      const options = [{ value: null, text: '-- Seleccione una empresa o desarrollo -- ' }]
      for (var i = 0; i < data.length; i++) {
        options.push({ value: { empresa_id: data[i].id, condominio_id: null, subcondominio_id: null }, text: `Empresa: ${data[i].razon_social}` })
        for (var j = 0; j < data[i].condominios.length; j++) {
          options.push({ value: { empresa_id: data[i].id, condominio_id: data[i].condominios[j].id, subcondominio_id: null }, text: `-- Desarrollo: ${data[i].condominios[j].nombre}` })
          for (var k = 0; k < data[i].condominios[j].subcondominios.length; k++) {
            options.push({ value: { empresa_id: data[i].id, condominio_id: data[i].condominios[j].id, subcondominio_id: data[i].condominios[j].subcondominios[k].id }, text: `---- Etapa: ${data[i].condominios[j].subcondominios[k].nombre}` })
          }
        }
      }
      this.options = options
    },

    async onChangeOption (value) {
      this.loading = true
      this.inversionistas = []
      this.selected = []
      // La información se filtrará de acuerdo al parámetro elegido por el usuario en el selector de empresas y desarrollos
      const response = await this.$store.dispatch('inversionistaModule/getSelectListAll', value)
      this.inversionistas = response.data.map((item) => {
        return { id: item.id, rfc: item.rfc, email: item.email, identificacion: item.identificacion, selected: false }
      })
      this.loading = false
    },

    selectRow (item) {
      // If it's actually selected remove
      if (this.isDuplicate(item)) {
        this.removeItem(item)
        this.inversionistas.find(row => row.id === item.id).selected = false
        return
      }

      this.selected.push(item)
      this.inversionistas.find(row => row.id === item.id).selected = true
    },

    onShowModal () {
      this.modal = true
      this.$emit('input', this.modal)
    },

    /**
     * Valida si el inversionista ya se encuentra seleccionado
     */
    isDuplicate (item) {
      return this.selected.map(item => item.id).includes(item.id)
    },

    /**
     * Valida si el inversionista que se muestra ya está en el array selected,
     * si es así entonces devuelve el nombre de una clave para aplicarla.
     *
     */
    isSelected (item) {
      return this.selected.map(item => item.id).includes(item.id) ? 'is__selected' : ''
    },

    /**
     * Elimina un elemento de los que están seleccionados.
     */
    removeItem (item) {
      this.selected = this.selected.filter(el => el.id !== item.id)
    },

    /**
     * Emite los nuevos valores del array.
     */
    emit (value) {
      this.$emit('input', value)
    }
  }
}
</script>
<style>
.required_form {
  color: red;
}
.isLoading {
  max-height: 400px !important;
  overflow: hidden;
}
.b-table-sticky-header {
  overflow-y: auto;
  max-height: 300px;
}
</style>
